.bgDartTheme {
  background-color: #121212;
  color: #ffffff;
}



.ui.segment:not(.basic),
.ui.attached.segment:not(.basic) {
  border: 1px solid rgb(241, 241, 241) !important;
  box-shadow:none !important;
  border-radius: 12px !important;
}

.bgDartTheme .ui.segment,
.bgDartTheme .ui.attached.segment {
  background-color: #242323 !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow:none !important;
  border-radius: 12px !important;
}

.ui.message,
.ui.negative.message {
  border: 1px solid rgb(240, 240, 240) !important;
  box-shadow: none !important;
  border-radius: 7px !important;
}

.bgDartTheme .ui.message,
.bgDartTheme .ui.negative.message {
  background-color: rgb(59, 28, 28);
  color: #ffffff;
  border: none !important;
  border-radius: 7px !important;
}

.ui.table:not(.offStyle) {
  border-radius: 12px !important;
  border: 1px solid rgb(241, 241, 241) !important;
}


.ui.table thead,.ui.table thead tr,.ui.table thead tr th:first-child {
  border-top-left-radius: 12px !important;
}

.ui.table thead,.ui.table thead tr,.ui.table thead tr th:last-child {
  border-top-right-radius: 12px !important;
}


.bgDartTheme .ui.table {
  border: none !important;
}



.bgDartTheme .ui.header,
.bgDartTheme .header,
.bgDartTheme label {
  color: #ffffff !important;
}


.bgDartTheme .ui.form input {
  background-color: #4b4b4b !important;
}


.bgDartTheme .ui.input>input {
  background-color: #1a1a1a;
  color: #ffffff;
}

.bgDartTheme .bgCenterPanel3,
.bgDartTheme .bgCenterPanel3 .ui.header,
.bgDartTheme .bgCenterPanel3 .sub.header {
  color: #ad2222 !important;
}

.bgDartTheme .imgPlayer img,.bgDartTheme .imgPlayer2 img {

  background-color: #bdbdbd !important;
}

.bgDartTheme .imgProfile {
  width: 170px !important;
  height: 170px !important;
  margin: 0 auto;
  background-color: #bdbdbd !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175)!important;
  border: #353535 1px solid !important;
}

.bgDartTheme .tllinkNoColor {
  color: #ffffff !important;
  font-size: 16px;
}

.bgDartTheme .tllink {
  color: #ff8b8b !important;
  font-size: 16px;
}


.tllinkC{
  color: #b11515 !important;
}

.bgDartTheme .tllinkC {
  color: #ffffff !important;
}

.bgDartTheme .tllinkv2 {
  color: #ebebeb !important;
}


.bgDartTheme .ui.secondary.pointing.menu .item {
  color: #ffffff;
}




.bgDartTheme .ui.card>.content>.description {
  color: #ffffff !important;
}

.bgDartTheme .panel-wrapper .fade {
  background: #2b2b2b;
  background: linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, rgba(43, 43, 43, 1) 75%);

}

.bgDartTheme .panel-wrappert .fadet {
  background: #2b2b2b;
  background: linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, rgba(43, 43, 43, 1) 75%);

}

.bgDartTheme .panel-wrappertl .fadetl {
  background: #2b2b2b;
  background: linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, rgba(43, 43, 43, 1) 75%);

}

.bgDartTheme .panel-wrapperDesc .fadeDesc {
  background: #2b2b2b;
  background: linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, rgba(43, 43, 43, 1) 75%);

}

.bgDartTheme .slick-prev,
.bgDartTheme .slick-next {
  -webkit-filter: invert(0);
  filter: invert(0);
}

.slick-prev,
.slick-next {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.CardBlack {
  background-color: #f1f1f1 !important;
}

.bgDartTheme .CardBlack {
  background-color: #1d1d1d !important;
}





.bgDartTheme .CardProfileAchieve {
  background: #2b2b2b !important;
  background: linear-gradient(40deg, rgba(68, 68, 68, 0.538) 30%, #2a3a21 100%) !important;
}

.CardProfileAchieve {
  background: #ffffff !important;
  background: linear-gradient(40deg, rgba(247, 247, 247, 0.538) 0%, rgb(240, 240, 240) 80%, #d7e0d6 100%) !important;
}


.bgDartTheme .fontColorPlayer {
  color: #f7f7f7 !important;
}

.fontColorPlayer2 {
  color: #4d4d4d !important;
  font-size: 12px !important;
}

.bgDartTheme .fontColorPlayer2 {
  color: #8f8f8f !important;
  font-size: 12px !important;
}

.bgDartTheme .wrappeddark .item {

  color: #f7f7f7 !important;
}


.bgDartTheme .colorSegmentCardDark {
  background-color: #424242 !important;
}

.bgDartTheme .colorSegmentCardDarkSukcesy1 {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(93, 133, 93, 0.538) 0%, rgb(68, 68, 68) 75%) !important;
}

.colorSegmentCardDarkSukcesy1 {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(177, 235, 177, 0.538) 0%, rgb(255, 255, 255) 75%) !important;
}


.bgDartTheme .colorCardLeague {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(158, 158, 158, 0.538) 0%, rgb(68, 68, 68) 75%) !important;

}

.colorCardLeague {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(238, 238, 238, 0.538) 0%, rgb(255, 255, 255) 75%) !important;
}

.bgDartTheme .tablebsv2 {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(158, 158, 158, 0.538) 0%, rgb(68, 68, 68) 75%) !important;
  border-radius: 12px !important;
}

.tablebsv2{
  box-shadow: 0 10px 20px 0 rgb(0 0 0/10%) !important;
  border-radius: 12px !important;
}

.colorSegmentCardDarkSukcesy0 {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(230, 230, 230, 0.538) 0%, rgb(248, 248, 248) 75%) !important;
}


.bgDartTheme .colorSegmentCardDarkSukcesy0 {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(137, 138, 137, 0.538) 0%, rgb(68, 68, 68) 75%) !important;
}

.colorSegmentCardDarkSukcesy3 {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(255, 255, 255) 0%, rgb(255, 255, 255) 75%) !important;
}

.tablePromotionRowMVP{
  background: linear-gradient(90deg, rgba(241, 189, 9, 0.10) 0%,  rgba(241, 189, 9, 0.20) 100%);
}

.tablePromotionRowUP {
  background: linear-gradient(90deg, rgba(3, 114, 62, 0.137) 0%, rgba(3, 114, 62, 0.137) 20%, rgba(255, 255, 255, 0) 100%);
}

.tablePromotionRowDown {
  background: linear-gradient(90deg, rgba(114, 3, 3, 0.137) 0%, rgba(114, 3, 3, 0.137) 20%, rgba(255, 255, 255, 0) 100%);
}

.colorLeagueWL{
  background: linear-gradient(90deg,rgba(3, 114, 62, 0.1) 0%,rgba(253, 253, 253, 0) 40%, rgb(253, 253, 253, 0) 60%, rgba(133, 1, 1, 0.07) 100%) !important;
}

.colorLeagueWR{
  background: linear-gradient(90deg,rgba(133, 1, 1, 0.07) 0%,rgba(253, 253, 253, 0) 40%, rgb(253, 253, 253, 0) 60%, rgba(3, 114, 62, 0.1) 100%) !important;
}

.colorLeagueD{
  background: linear-gradient(90deg,rgba(124, 122, 0, 0.1) 0%,rgba(253, 253, 253, 0) 40%, rgb(253, 253, 253, 0) 60%, rgba(124, 122, 0, 0.1) 100%) !important;

}

.bgDartTheme .colorLeagueWL{
  background: linear-gradient(90deg,rgba(3, 114, 62, 0.17) 0%,rgba(253, 253, 253, 0) 40%, rgb(253, 253, 253, 0) 60%, rgba(133, 1, 1, 0.1) 100%) !important;
}

.bgDartTheme .colorLeagueWR{
  background: linear-gradient(90deg,rgba(133, 1, 1, 0.1) 0%,rgba(253, 253, 253, 0) 40%, rgb(253, 253, 253, 0) 60%, rgba(3, 114, 62, 0.17) 100%) !important;
}

.bgDartTheme .colorLeagueD{
  background: linear-gradient(90deg,rgba(124, 122, 0, 0.1) 0%,rgba(253, 253, 253, 0) 40%, rgb(253, 253, 253, 0) 60%, rgba(124, 122, 0, 0.1) 100%) !important;

}


.iconAF {
  filter: grayscale(1)
}

.bgDartTheme .iconAF {
  filter: brightness(0) invert(1);
}

.bgDartTheme .colorSegmentCardDarkSukcesy3 {
  background: #2b2b2b !important;
  background: linear-gradient(180deg, rgba(137, 138, 137, 0.538) 0%, rgb(68, 68, 68) 75%) !important;
}

.bgDartTheme .bgpodium {
  background: #2b2b2b !important;
  background: linear-gradient(0deg, rgba(59, 59, 59, 0.538) 0%, rgba(63, 63, 63, 0.945) 75%) !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.bgpodium {
  background: #2b2b2b !important;
  background: linear-gradient(0deg, rgba(238, 238, 238, 0.538) 0%, rgba(236, 236, 236, 0.959) 75%) !important;
  -webkit-box-shadow: 0px -14px 65px -39px rgba(57, 57, 57, 1);
  -moz-box-shadow: 0px -14px 65px -39px rgba(57, 57, 57, 1);
  box-shadow: 0px -14px 65px -39px rgb(114, 114, 114);
}

.ui.block.header.ps {
  border: 1px solid #d4d4d5;
}

.bgDartTheme .ui.block.header.ps {
  border: none;
}

.bgpane {
  background-color: #f1f1f1 !important;
}

.bgpaneELO {
  background-color: #f1f1f1 !important;
}

.bgDartTheme .bgpane {
  background: linear-gradient(136deg, #1b1b1b, #202020) !important;
  background-size: 1000% 1000%;
  border: none !important;
  color: #f5f5f5 !important;

}

.bgpaneELO {
  text-align: center;
}

.bgDartTheme .bgpaneELO {
  text-align: center;
  background: linear-gradient(136deg, #1b1b1b, #202020) !important;
  background-size: 1000% 1000%;
  color: #f5f5f5 !important;
  border: none !important;
}

.bgDartTheme .noBorder {
  border: none !important;
  box-shadow: none !important;
}

#podium0,
#podium1,
#podium2 {
  width: 100%;
  background: #c2c1c1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  vertical-align: middle;
  color: #a7a7a7;

}

.bgDartTheme #podium0,
.bgDartTheme #podium1,
.bgDartTheme #podium2 {
  width: 100%;
  background: #201f1f;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  vertical-align: middle;
  color: #222222;
}


.comparem {
  position: relative;
}

.bgDartTheme .comparem {
  position: relative;
  background-color: #252525 !important;
  color: #dddddd !important;
}

.bgDartTheme .comparem2 {
  position: relative;
  color: #dddddd !important;
}

.bgDartTheme .comparem2 tr:nth-child(odd) {
  background-color: #313131 !important;
}

.bgDartTheme .comparem2,
.bgDartTheme .comparem2 tr,
.bgDartTheme .comparem2 th,
.bgDartTheme .comparem2 td {
  border: 1px solid rgb(29, 29, 29) !important;
}

.bgDartTheme .comparem,
.bgDartTheme .comparem tr,
.bgDartTheme .comparem th,
.bgDartTheme .comparem td {
  border: 1px solid rgb(29, 29, 29) !important;
}

.comparem,
.comparem tr,
.comparem th,
.comparem td {
  border: 1px solid rgb(231, 231, 231) !important;
}

.comparem .secondtrans,
.comparem1 .secondtrans {
  transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1)
}


.colorIconTab {
  color: #686868;
}

.comparem a,
.comparem2 a {
  color: #303030;
  text-decoration: none;
}

.comparem a:hover,
.comparem2 a:hover {
  color: #f50b0b;
  text-decoration: none;
}

.bgDartTheme .colorIconTab {
  color: #c0c0c0;
}

.bgDartTheme .comparem a,
.bgDartTheme .comparem2 a {
  color: #ffffff;
  text-decoration: none;
}

.bgDartTheme .comparem a:hover,
.bgDartTheme .comparem2 a:hover {
  color: #f50b0b;
  text-decoration: none;
}



.winnerScoreP {
  background-color: #d6e7cb !important;
}

.loserScoreP {
  background-color: #ebc4c4 !important;
}

.drawScoreP {
  background-color: rgb(255, 243, 216) !important;
}

.bgDartTheme .winnerScoreP {
  background-color: #445e38 !important;
}

.bgDartTheme .loserScoreP {
  background-color: #5a4242 !important;
}

.bgDartTheme .drawScoreP {
  background-color: rgb(122, 104, 66) !important;
}

.tlalltablescore {
  max-height: 760px;
  overflow-y: auto;
  overflow-x: visible;
  overflow-wrap: normal;
  margin-top: 20px;
  padding: 5px;
}

.tlalltablescoreSeg {
  background: rgb(255, 255, 255) !important;
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgb(238, 238, 238) 100%) !important;
}

.bgDartTheme .tlalltablescoreSeg {
  background: rgb(54, 54, 54) !important;
}

.bgDartTheme .ui.divided.grid:not([class*="vertically divided"])>.row>.column {
  box-shadow: -1px 0 0 0 rgba(180, 180, 180, 0.15);
}

.tableDuel {
  width: 100%;
  height: 200px !important;
  text-align: center;
  cursor: default;
  overflow: auto;
}

.tableDuel th {
  vertical-align: middle;
  font-weight: normal;
  padding: 1.0vw;
  font-size: 3.0vw;

}

.tableDuel td {
  font-size: 6.0vw;
  vertical-align: middle;
  font-weight: normal;
  padding: 3.0vw 2.0vw 3.0vw 2.0vw;

}

.scoreFooter{
  height: 350px;
  position: fixed;
  left: 0;
  bottom: 1px;
  width: 100%;
}

.tgkeyboard{

  height: 200px;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.tableKeyboardTG{
  width: 100%;
  text-align: center;
  cursor: default;
  overflow: auto;
 
}

.tableKeyboardTG td {
  font-size: 40px;
  width: 33%;
  height: 20%;
  vertical-align: middle;
  font-weight: normal;
  border: #121212 1px solid;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba(187, 187, 187, 0.89);
}

.tableKeyboardTG td:hover,.bgDartTheme .tableKeyboardTG td:hover {
  background-color: rgba(184, 128, 128, 0.89);
  color: #FFF;
}

.bgDartTheme .tableKeyboardTG td {
  border: #585858 1px solid;
  background-color: rgba(65, 65, 65, 0.89);
}

.viewScoreDart {
  height: 150px;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.wrapSmallPtk{
  height: 150px;
  width: 100px;
  background-color: #e9e9e9;
}

.bgDartTheme .wrapSmallPtk {
  height: 150px;
  width: 100px;
  background-color: #474747;
}


.wrapSmallPtk .SmallPtk{
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: #666666 1px solid;
}

.viewScoreTotalActiveTABSmall{
  background-color: #b49355;
}


.viewScoreDart .viewScoreTotal {
  background-color: #aaaaaa;
  height: 100%;
  font-size: 20.0vmin;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

}

.viewScoreTotalActiveTAB{
  background-color: #36947c;
}

.scoreFooter .viewScoreTotalActive {
  background-color: #97b9d8;
  height: 100%;
  font-size: 20.0vmin;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgDartTheme .scoreFooter .viewScoreTotal {
  background-color: #1a222c;
}


.bgDartTheme .scoreFooter .viewScoreTotalActive {
  background-color: #2f3f5e;
}


.inputDartCurrentRow {
  padding: 0;
  margin: 0;
}


.inputDartCurrentRow input {
  padding: 5px !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.inputDartCurrentRow input:focus {
  background-color: #8bc7ff !important;
}

.cardStatsPS{
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}

.bgDartTheme .cardStatsPS {
  background-color: #3f3f3f !important;
}

.cardStatsPS .cbody{
  flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.colorRowTabH2H{
  background-color: #f5f5f5;
}


.bgDartTheme .colorRowTabH2H{
  background-color: #2e2e2e;
}

.bgDartTheme .p-tabview-panels{
  background-color: #2e2e2e !important;
}

.bgDartTheme .p-tabview-nav,.bgDartTheme .p-unselectable-text{
  background-color: #2e2e2e !important;
}


.bgDartTheme .p-tabview-nav-btn{
  background-color: #1f1f1f !important;
}

.p-tabview-nav,.p-unselectable-text{
  background-color: #ffffff !important;
}

.bgDartTheme .p-accordion-header-text,.bgDartTheme .p-accordion-toggle-icon{
  color: #ebebeb !important;
}


.bgDartTheme .p-accordion-content{
  background-color: #1f1f1f !important;
}




.bgTeamMemberProfileMatches{
  background-color: #eeeeee !important;
  border-top: solid 1px #c2c2c2 ;
}

.bgDartTheme .bgTeamMemberProfileMatches{
  background-color: #444444 !important;
  border-top: solid 1px #686868 ;
}


/*
.ui.table.stickyf2c tr:hover td{
  background-color: #f3dbdb !important;

   -moz-transition: all 1s ease-in;

   -webkit-transition: all 1s ease-in;

   -o-transition: all 1s ease-in;

   transition: all 1s ease-in;
}
*/

 .p-accordion-header-link{
  background: #fafafa;
}

.p-accordion-content{
  background: #ffffff;
}

.bgDartTheme .p-accordion-header-link,.bgDartTheme .p-accordion-content{
  border: 1px solid #464646;
  background: #3a3a3a;
}

.rowSelectedColor tr:hover{
  background-color: #00a2ff2c !important;
}

.bgDartTheme .rowSelectedColor tr:hover{
  background-color: #394f66fd !important;
 
}



.bgDartTheme .stickyf2c tr:nth-child(even):not(:hover) td:not(.winnerScoreP):not(.loserScoreP) {
  background-color: #3d3d3d !important;
}

.bgDartTheme .stickyf2c tr:nth-child(odd):not(:hover)  td:not(.winnerScoreP):not(.loserScoreP) {
  background-color: #333333 !important;
}


.bgDartTheme .ui.selection.dropdown {
  background : #202020 !important;
  color: #fff
}

.bgDartTheme .tabTGStyle div.item{
  background : #3a3a3a !important;
  color: #e7e7e7 !important;
  border: solid 1px #646464 !important;
  font-weight: bold !important;
}

.bgDartTheme .tabTGStyle div.active{
  background-color: #a56740 !important;

}

.bgpaneELO .p-tabview .p-tabview-panels,.bgpaneELO .p-tabview-nav, .bgpaneELO .p-unselectable-text{
background: none;
background-color: #ffffff00 !important;
}

.tabTGStyle div.item{
  background-color: #f5f5f5 !important;
  border-radius: 3px !important;
  margin:3px !important; 
  font-size: 13px !important;
  flex: 1 1 250px !important;
  text-align: center !important;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center !important;
  line-height: 17px !important;
  height: 60px;
  color: #181818 !important;
  text-align: center;
  align-items: center; /* nope */
  justify-content: center; /* nope */
  align-content: center; /* nope */
  vertical-align: middle; /* nope */
  border: solid 1px #a5a5a5 !important;
  font-weight: bold !important;
  cursor: pointer;

}

.tabTGStyle div.item.active{
  background-color: #a56740 !important;
  color: #ffffff !important;
  border: solid 1px #a56740 !important;
  cursor: pointer;

}