.countdown {
  text-align: center;
  font-weight: bold;
}

.countdown .cdt ul {
  padding: 0;
  margin: 0 auto;
  width: 300px;
}

.countdown .cdt li {
  background-color: #cacaca36;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  list-style-type: none;
  
  padding: 7px;
  margin: 2px;
  width: 70px;
  text-transform: uppercase;
}

.countdown .cdt li span {
  display: block;
  font-size: 20px;
}


.countdownsizeMini {
  text-align: center;
  font-weight: bold;
}

.countdownsizeMini .cdt ul {
  padding: 0;
  margin: 0 auto;
  width: 310px;
}

.countdownsizeMini .cdt li {
  background-color: #cacaca36;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  list-style-type: none;
  
  padding: 10px;
  margin: 2px;
  width:70px;
  text-transform: uppercase;
}

.countdownsizeMini .cdt li span {
  display: block;
  font-size: 14px;
}





.countdown .cdt1 ul {
  padding: 0;
  margin: 0 auto;
 
}

.countdown .cdt1 li {

  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  list-style-type: none;
  
  padding: 7px;
  margin: 2px;
 
  text-transform: uppercase;
}

.countdown .cdt1 li span {
  display: block;
  font-size: 20px;
}



.countdownsizeMini .cdt1 ul {
  padding: 0;
  margin: 0 auto;
 
}

.countdownsizeMini .cdt1 li {

  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  list-style-type: none;
  
  padding: 10px;
  margin: 2px;

  text-transform: uppercase;
}

.countdownsizeMini .cdt1 li span {
  display: block;
  font-size: 14px;
}