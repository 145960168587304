.ui.stackable.grid>.row>.wide.column.amp0{
    margin: 0 !important;
    padding: 0 !important;
}

.amp0{
    margin: 10px 0 10px 0 !important;
    padding: 2px !important;
    
}

.ag-theme-balham .ag-root-wrapper{
    border-radius: 5px;
}

